import React from 'react'
// import { Route, Link } from 'react-router-dom'
import './morris.css'
import './own.css'
import { Link, Redirect } from 'react-router-dom'
// import {isLogged} from '../shared/AuthGuard'


const Sidebar = () => {
    function clearLogin() {
        // alert("Logging Out !!")
        localStorage.clear();
    }
    return (
        <div>
            <nav className="navbar navbar-primary navbar-static-top" role="navigation" style={{ marginBottom: 0 }}>
                <div className="navbar-header">
                    <button type="button" className="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
                        <span className="sr-only">Toggle navigation</span>
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                    </button>
                    {/* <span> */}
                    <a className="navbar-brand">
                        <img src={require('./../../assets/img/logo.png')} alt="" title="" />
                    </a>


                    {/* </span> */}
                </div>
                <div className="right-menu pull-right">
                    <ul className="list-inline">
                        <li > <Link to="/admin/changePassword" className="m20" style ={{ color : 'white' ,marginRight : 20+'px', marginBottom: 10 +'px'}} >Change Password  </Link> </li>
                        <li> <Link onClick={clearLogin} to="/"><i className="fa fa-2x fa-sign-out"></i></Link></li>
                    </ul>

                </div>
                <div className="navbar-default sidebar" role="navigation">
                    <div className="sidebar-nav navbar-collapse">
                        <ul className="nav" id="side-menu">

                            {/* <li>
                            <Link to="/admin/dashboard">
                                <i className="fa fa-caret-right fa-fw"></i> Dashboard
                            </Link>
                        </li> */}
                            <li onClick={isLoggedIn} >
                                <Link to="/admin/genre"  >
                                    <i className="fa fa-caret-right fa-fw"></i> Genre
                            </Link>
                            </li>
                            <li onClick={isLoggedIn}>
                                <Link to="/admin/sub_genre">
                                    <i className="fa fa-caret-right fa-fw"></i> Sub-Genre
                            </Link>
                            </li>
                            <li onClick={isLoggedIn}>
                                <Link to="/admin/musician">
                                    <i className="fa fa-caret-right fa-fw"></i> Instrument Categories
                            </Link>
                            </li>
                            <li onClick={isLoggedIn}>
                                <Link to="/admin/instruments">
                                    <i className="fa fa-caret-right fa-fw"></i> Instruments
                            </Link>
                            </li>
                            <li onClick={isLoggedIn}>
                                <Link to="/admin/fanManagement">
                                    <i className="fa fa-caret-right fa-fw"></i> Fan Management
                            </Link>
                            </li>
                            <li onClick={isLoggedIn}>
                                <Link to="/admin/adminManagement">
                                    <i className="fa fa-caret-right fa-fw"></i> Admin Management
                            </Link>
                            </li>
                            <li onClick={isLoggedIn}>
                                <Link to="/admin/earnings">
                                    <i className="fa fa-caret-right fa-fw"></i> Earning Statistics
                            </Link>
                            </li>
                            <li onClick={isLoggedIn}>
                                <Link to="/admin/role">
                                    <i className="fa fa-caret-right fa-fw"></i> Role Management
                            </Link>
                            </li>
                            <li onClick={isLoggedIn}>
                                <Link to="/admin/blogCategoryManagement">
                                    <i className="fa fa-caret-right fa-fw"></i> Blog Category Management
                            </Link>
                            </li>
                            <li onClick={isLoggedIn}>
                                <Link to="/admin/blogManagement">
                                    <i className="fa fa-caret-right fa-fw"></i> Blog Management
                            </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    )
}

function isLoggedIn() {
    if (!localStorage.getItem('tokenAdmin')) {
        window.location.href = "/";
    }
    return true;
}
export default Sidebar