import React, { Component } from 'react';
import axios from 'axios';
import { notify } from 'react-notify-toast';
import Pagination from './pagination';

// import * as url from  '../shared/routes.js'

class Musicians extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            newRoleName: "",
            newRoleDescription: "",
            showEditModel: false,
            editName: "",
            editDescription: "",
            roleEditId: '',
            formIsValid: '',
            isActive: '',
            errors: '',
            pageNo: 1,
            limit: 20,
            totalPages: '',

        };
        this.componentWillMount = this.componentWillMount.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.addNew = this.addNew.bind(this);
        this.showModal = this.showModal.bind(this);
        this.changePage = this.changePage.bind(this)
        this.editEntry = this.editEntry.bind(this);
    }
    componentWillMount() {
        var self = this;
        axios.get("/instrument_category" + "?page=" + this.state.pageNo + "&limit=" + this.state.limit)
            .then(function (response) {
                if (response.data.data.result) {
                    // notify.show('All Entries Loded', 'success', 1400);
                    self.setState({
                        data: response.data.data.result,
                        totalPages: response.data.data.pages
                    })
                }
            })
            .catch(function (error) {
            });
    }
    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    showModal = (selectedRow) => {
        this.setState({errors : null})
        this.setState({
            editName: selectedRow.name,
            editDescription: selectedRow.description,
            roleEditId: selectedRow.instrument_category_id,
            isActive: selectedRow.is_active
        });
    };
    handleValidation(addOREdit) {
        if (addOREdit == 'new') {
            if (this.state.newRoleName == null || this.state.newRoleName == '') {
                this.setState({ formIsValid: false, })
                this.setState({ errors: "Field is required" })
            }
            else {
                this.state.errors = null;
                this.state.formIsValid = true;
            }
        }
        else {
            if (this.state.editName == null || this.state.editName == '') {

                this.setState({ formIsValid: false, })
                this.setState({ errors: "Field is required" })
            }
            else {
                //last cursor
                this.state.errors = null;
                this.state.formIsValid = true;
            }
        }
        console.log("All States", this.state);
        return this.state.formIsValid;

    }
    addNew() {
        if (!this.handleValidation("new")) {
            return 2;
        }
        var self = this;
        var config = {
            headers: { 'Authorization': "Bearer " + localStorage.getItem("tokenAdmin") }
        };
        let activeState = this.state.isActive ? 1 : 0;
        axios.post("/instrument_category", {
            name: this.state.newRoleName,
            description: this.state.newRoleDescription,
            is_active: activeState
        }, config)
            .then(function (response) {

                if (response.data.success) {
                    window.$('#exampleModalCenter').modal('hide');
                    notify.show('Instrument Categories Added', 'success', 1400);
                    self.componentWillMount();
                }
                notify.show('Fields required ', 'error', 1400);

            })
            .catch(function (error) {
            });
    }
    editEntry() {
        if (!this.handleValidation("edit")) {
            return 2;
        }
        // 
        var self = this;
        var config = {
            headers: { 'Authorization': "Bearer " + localStorage.getItem("tokenAdmin") }
        };
        let activeState = this.state.isActive ? 1 : 0;
        axios.put("/instrument_category", {
            name: this.state.editName,
            description: this.state.editDescription,
            is_active: activeState,
            id: this.state.roleEditId
        }, config)
            .then(function (response) {
                if (response.data.success) {
                    window.$('#editModal').modal('hide');
                    notify.show('Instrument Categories Updated', 'success', 1400);
                    self.componentWillMount();
                }

            })
            .catch(function (error) {
                //alert("jnk");
                console.log(error);
            });
    }

    changePage(pageNumber, limitEntry) {

        this.setState({
            pageNo: pageNumber,
            limit: limitEntry
        }, () => {
            this.componentWillMount();
        });
    }

    render() {
        return (
            <div id="page-wrapper">
                <div className="row">
                    <div className="col-md-6">
                        <h1>Instrument Categories Management</h1>
                    </div>
                    <div className="col-md-6">
                        <button type="button" className=" fa fa-plus btn btn-primary pull-right" data-toggle="modal" data-target="#exampleModalCenter">
                            Add Instrument Categories
                        </button>
                    </div>
                </div>
                <table width="100%" className="table table-striped table-bordered table-hover" id="dataTables-example">
                    <thead>
                        <tr>
                            <th>Instrument Categories </th>
                            <th>Description</th>
                            <th>Active</th>
                            <th>Date Created</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.data.map(row =>
                            <tr className="odd gradeX">
                                <td>{row.name}</td>
                                <td>{row.description}</td>
                                {(row.is_active === 0 || row.is_active == null) &&
                                    <td> Not Active </td>
                                }
                                {row.is_active === 1 &&
                                    <td>  Active </td>
                                }
                                <td>{row.created_at.split("T")[0]}</td>
                                <td className="center">
                                    <ul className="list-inline">
                                        <li><i className=" fa fa-edit" onClick={() => this.showModal(row)} data-toggle="modal" data-target="#editModal" > </i></li>
                                    </ul>
                                </td>
                            </tr>)}
                    </tbody>
                </table>

                <Pagination
                    changePage={this.changePage}  //function Call 
                    totalPages={this.state.totalPages}
                    limit={this.state.limit}
                    currentPage={this.state.pageNo}
                />


                {/* Add Modal  */}
                <div className="modal fade" id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLongTitle">Add Instrument Category </h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <label htmlFor="newRoleName">New Instrument Category Name <span style={{ color: "red" }} > * </span></label>
                                <div className="form-group">
                                    <input className="form-control"
                                        placeholder="New Instrument Categories Name"
                                        name="newRoleName"
                                        type="text"
                                        autoFocus
                                        autoComplete="true"
                                        onChange={this.handleInputChange} />
                                    <span style={{ color: "red" }} > {this.state.errors}</span>

                                </div>
                                <label htmlFor="newRoleDescription">Description</label>
                                <div className="form-group">
                                    <input className="form-control"
                                        placeholder="Description"
                                        name="newRoleDescription"
                                        type="text"
                                        onChange={this.handleInputChange} />
                                </div>
                                <div class="form-group">
                                    <span class="switch switch-sm">
                                        <input type="checkbox" class="switch" id="switch-sm" name="isActive" onChange={this.handleInputChange} />
                                        <label for="switch-sm">Active</label>
                                    </span>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button type="button" className="btn btn-primary" onClick={this.addNew} > Add</button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Edit Modal */}
                <div show="false" className="modal fade" id="editModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLongTitle">Edit Instrument Category</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <label htmlFor="editName">Edit Instrument Category Name <span style={{ color: "red" }} > *</span></label>
                                <div className="form-group">
                                    <input className="form-control"
                                        placeholder="Instrument Name"
                                        name="editName"
                                        type="text"
                                        autoFocus
                                        autoComplete="true"
                                        onChange={this.handleInputChange}
                                        value={this.state.editName}
                                    />
                                    <span style={{ color: "red" }} className="error" > {this.state.errors}</span>

                                </div>
                                <label htmlFor="editDescription">Description</label>
                                <div className="form-group">
                                    <input className="form-control"
                                        placeholder="Description"
                                        name="editDescription"
                                        type="text"
                                        onChange={this.handleInputChange}
                                        value={this.state.editDescription}
                                    />
                                </div>
                                <div class="form-group">
                                    <span class="switch switch-sm">
                                        <input type="checkbox" class="switch" id="switch-sm" name="isActive" onChange={this.handleInputChange} checked={this.state.isActive} />
                                        <label for="switch-sm">Active</label>
                                    </span>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button type="button" className="btn btn-primary" onClick={this.editEntry} >Update </button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    };
}
export default Musicians
