import React, { Component } from 'react';
import axios from 'axios';
import { notify } from 'react-notify-toast';
import Pagination from './pagination';

class Instruments extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            Musiciandata: [],
            newRoleName: "",
            newRoleDescription: "",
            showEditModel: false,
            editGenreName: "",
            editRoleDescription: "",
            roleEditId: '',
            selectmusician: '',
            selectMusicianError: '',
            formIsValid: '',
            errors: '',
            pageNo: 1,
            limit: 20,
            totalPages: '',
            field: 'name',
            searchFor: '',

        };
        this.componentWillMount = this.componentWillMount.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.addNew = this.addNew.bind(this);
        this.showModal = this.showModal.bind(this);
        this.editEntry = this.editEntry.bind(this);
        this.changePage = this.changePage.bind(this)

    }
    header = ['name', 'Instrument Category ']

    componentWillMount() {
        this.getInstruments();
        this.getMusicians();
    }
    getInstruments() {
        var self = this;
        // if (this.state.field === 'Instrument Category') {
        //     debugger
        //     this.setState({ searchFor: this.searchId(this.state.searchFor, this.state.Musiciandata).instrument_category_id })
        // }
        axios.get("/instrument?page=" + this.state.pageNo + "&limit=" + this.state.limit + "&field=" +this.state.field  +  "&search=" + this.state.searchFor)
            .then(function (response) {
                if (response.data.data.result) {
                    // notify.show('All Entries Loded', 'success', 1400);
                    self.setState({
                        data: response.data.data.result,
                        totalPages: response.data.data.pages
                    })
                }
            })
            .catch(function (error) {
                //alert("jnk")
                console.log(error);
            });
    }

    getMusicians() {
        var self = this;

        axios.get("/instrument_category?page=1&limit=210")
            .then(function (response) {
                if (response.data.data.result) {
                    self.setState({ Musiciandata: response.data.data.result })
                }
            })
            .catch(function (error) {
                //alert("jnk")
                console.log(error);
            });
    }

    handleInputChange(event) {
        debugger
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }



    showModal = (selectedRow) => {
        this.setState({ errors: null })
        this.setState({
            editRoleName: selectedRow.name,
            editRoleDescription: selectedRow.description,
            roleEditId: selectedRow.musician_instrument_id,
            selectmusician: selectedRow.instrument_category.name
        });
        //alert("done")
    };
    searchId = (nameKey, myArray) => {

        for (var i = 0; i < myArray.length; i++) {
            if (myArray[i].name == nameKey) {
                return myArray[i];
            }
        }
    }


    handleValidation(addOREdit) {
        if (addOREdit == 'new') {
            if (this.state.newRoleName == null || this.state.newRoleName == '') {

                this.setState({ formIsValid: false,errors: "Field is required" })
                return false
            }
            else {
                this.setState({ formIsValid: true,errors: null })
                return true
            }
        }
        else {
            if (this.state.editRoleName == null || this.state.editRoleName == '') {

                this.setState({ formIsValid: false,errors: "Field is required" })
                return false
            }
            else {
                this.setState({ formIsValid: true,errors: null })
                return true
            }
        }
    }

    validateMusician() {
        if (this.state.selectmusician == null || this.state.selectmusician == '') {
            this.setState({ formIsValid: false,selectMusicianError: "Field is required" })
            return false
        } else {
            this.setState({ formIsValid: true,selectMusicianError:'' })
            return true
        }
    }

    addNew() {
        if (!this.handleValidation("new")) {
            console.log("nbnbnbnb")
            return 2;
        }
        if(!this.validateMusician()) {
            return false
        }

        var self = this;
        var config = {
            headers: { 'Authorization': "Bearer " + localStorage.getItem("tokenAdmin") }
        };
        let instrument_category = this.searchId(this.state.selectmusician, this.state.Musiciandata).instrument_category_id;
        axios.post("/instrument", {
            name: this.state.newRoleName,
            description: this.state.newRoleDescription,
            is_active: 1,
            instrument_category_id: instrument_category
        }, config)
            .then(function (response) {
                if (response.data.success) {
                    window.$('#exampleModalCenter').modal('hide')
                    notify.show('Instrument Added', 'success', 1400);
                    self.getInstruments();
                }
                else {
                    notify.show('Already Exist', 'error', 1400);
                }
            })
            .catch(function (error) {
                notify.show(error, 'error', 1200)
            });

    }
    editEntry() {
        if (!this.handleValidation("edit")) {
            // notify.show('Invalid Form Entries', 'error', 1400);s
            return 2;
        }
        if(!this.validateMusician()) {
            return false
        }
        var self = this;

        var config = {
            headers: { 'Authorization': "Bearer " + localStorage.getItem("tokenAdmin") }
        };
        let instrument_category = this.searchId(this.state.selectmusician, this.state.Musiciandata).instrument_category_id;

        axios.put("/instrument", {
            name: this.state.editRoleName,
            description: this.state.editRoleDescription,
            is_active: 1,
            id: this.state.roleEditId,
            instrument_category_id: instrument_category
        }, config)
            .then(function (response) {
                if (response.data.success) {
                    window.$('#editModal').modal('hide')
                    notify.show('Instrument Updated', 'success', 1400);
                    self.getInstruments();
                }
            })
            .catch(function (error) {
                //alert("jnk");
                console.log(error);
            });
    }

    changePage(pageNumber, limitEntry) {

        this.setState({
            pageNo: pageNumber,
            limit: limitEntry
        }, () => {
            this.getInstruments();
        });
    }
    render() {
        let musicians = this.state.Musiciandata;
        let optionItems = musicians.map((instrument_category) =>
            <option key={instrument_category.instrument_category_id}>{instrument_category.name}</option>
        );
        return (
            <div id="page-wrapper" class="page-heading">
                <div className="row mb20">
                    <div className="col-md-4">
                        <h2>Instrument Management</h2>
                    </div>
                    
                    <div className="col-md-6">
                        <div className="search-input-wrapper">
                        <ul class="list-inline"> 
                            <li><label htmlFor="search" class="search-title"> Search By Name </label></li>
                            <li><input class="form-control" id="search" type = "text" name="searchBar" onChange={ (e) =>{this.setState ({ searchFor : e.target.value })}} /> </li>
                            <li><span onClick={(e) => this.getInstruments(e)}><i class="fa fa-search"  ></i></span></li>
                            </ul>
                        </div>
                        
                    
                    </div>
                    <div className="col-md-2">
                    <button type="button" className=" fa fa-plus btn btn-primary pull-right" data-toggle="modal" data-target="#exampleModalCenter">
                            Add Instrument
                        </button>
                    </div>
                </div>
                <table width="100%" className="table table-striped table-bordered table-hover" id="dataTables-example">
                    <thead>
                        <tr>
                            <th>Instrument </th>
                            <th>Description</th>
                            <th>Instrument Category </th>
                            <th>Date Created</th>
                            <th>Actions</th>

                        </tr>
                    </thead>
                    <tbody>
                        {this.state.data.map(row =>
                            <tr className="odd gradeX">
                                <td>{row.name}</td>
                                <td>{row.description}</td>
                                <td>{row.instrument_category.name}</td>
                                <td>{row.created_at.split("T")[0]}</td>

                                <td className="center">
                                    <ul className="list-inline">
                                        <li><i className=" fa fa-edit" onClick={() => this.showModal(row)} data-toggle="modal" data-target="#editModal" > </i></li>
                                    </ul>
                                </td>
                            </tr>)}
                    </tbody>
                </table>

                <Pagination
                    changePage={this.changePage}  //function Call 
                    totalPages={this.state.totalPages}
                    limit={this.state.limit}
                    currentPage={this.state.pageNo}
                />

                {/* Add Modal  */}
                <div className="modal fade" id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLongTitle">Add Instruments</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <label htmlFor="newRoleName">New Instrument Name  <span style={{ color: "red" }} > *</span></label>
                                <div className="form-group">
                                    <input className="form-control"
                                        placeholder="New Instrument Name"
                                        name="newRoleName"
                                        type="text"
                                        autoFocus
                                        autoComplete="true"
                                        onChange={this.handleInputChange} />
                                    <span style={{ color: "red" }} > {this.state.errors}</span>

                                </div>

                                <label htmlFor="newRoleDescription">Description</label>
                                <div className="form-group">
                                    <input className="form-control"
                                        placeholder="Description"
                                        name="newRoleDescription"
                                        type="text"
                                        onChange={this.handleInputChange} />
                                </div>
                                <label htmlFor="selectmusician">Select Instrument Category</label>
                                <select className="form-control" onChange={this.handleInputChange} name="selectmusician" >
                                    <option value=""> Select One</option>
                                    {optionItems}
                                </select>
                                <span style={{ color: "red" }} > {this.state.selectMusicianError}</span>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button type="button" className="btn btn-primary" onClick={this.addNew} > Add</button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Edit Modal */}
                <div show="false" className="modal fade" id="editModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLongTitle">Edit Instruments</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <label htmlFor="editRoleName">Instrument  <span style={{ color: "red" }} > *</span> </label>
                                <div className="form-group">
                                    <input className="form-control"
                                        placeholder="Instrument Name"
                                        name="editRoleName"
                                        type="text"
                                        autoFocus
                                        autoComplete="true"
                                        onChange={this.handleInputChange}
                                        value={this.state.editRoleName}
                                    />
                                    <span style={{ color: "red" }} > {this.state.errors}</span>

                                </div>
                                <label htmlFor="editRoleDescription">Description</label>
                                <div className="form-group">
                                    <input className="form-control"
                                        placeholder="Description"
                                        name="editRoleDescription"
                                        type="text"
                                        onChange={this.handleInputChange}
                                        value={this.state.editRoleDescription}
                                    />
                                </div>
                                <label htmlFor="selectmusician">Select Musician Role</label>
                                <select value={this.state.selectmusician} className="form-control" onChange={this.handleInputChange} name="selectmusician" value={this.state.selectmusician} >
                                    <option value=""> Select One</option>
                                    {optionItems}
                                </select>
                                <span style={{ color: "red" }} > {this.state.selectMusicianError}</span>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button type="button" className="btn btn-primary" onClick={this.editEntry} >Update </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    };
}
export default Instruments
