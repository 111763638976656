import React,{ Component }  from 'react'

class Users extends Component {
    constructor(props) {
        super(props);
        this.state = {  
        };

    }
    render() {
        return (
            <div id="page-wrapper">
                <h1>Samples</h1>
            </div>
        )
    };
}

export default Users
