import React, { Component } from 'react'
import axios from 'axios';
import $ from "jquery";
import Pagination from './pagination';
import { notify } from 'react-notify-toast';

class Earnings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data:[],
            pageNo:1,
            limit:10
        };

    }
    componentWillMount() {
        this.IntitialDataCall()
        // alert("Loaded")getEarning
    }


    IntitialDataCall=()=> {
        var self = this;
        // this.setState({data:[1,2,3]})
        var config = {
            headers: { 'Authorization': "Bearer " + localStorage.getItem("tokenAdmin") }
        };
        axios.get("/auth/super_admin/getEarning" + "?page=" + this.state.pageNo + "&limit=" + this.state.limit,config)
            .then(function (response) {
                if (response.data.data.result) {

                    // notify.show('All Entries Loded', 'success', 1400);
                    console.log("This is Response ", response.data.data);
                    self.setState({
                        data: response.data.data.result,
                        totalPages: response.data.data.pages
                    })
                }
            })
            .catch(function (error) {
                notify.show('Network Connection Error', 'error', 1400);
                console.log(error);
            });
    }

    changePage=(pageNumber, limitEntry)=> {

        this.setState({
            pageNo: pageNumber,
            limit: limitEntry
        }, () => {
            this.IntitialDataCall();
        });
    }

    render() {
        return (
            <div id="page-wrapper">
                <table width="100%" className="table table-striped table-bordered table-hover" id="dataTables-example">
                    <thead>
                        <tr>
                            <th>DAlbum</th>
                            <th>Manager Name</th>
                            <th>DAlbum Sold</th>
                            {/* <th>Dextra Sold</th> */}
                            <th>Publishing Fee</th>
                            {/* <th>Total Amount Earned</th> */}
                            <th>Manager Earnings </th>
                            {/* <th>Dalbum Commission </th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.data.map((elem,index) => <tr>
                            <td>{elem.dalbum.name}</td>
                            <td>{elem.admin.firstname} {elem.admin.lastname}</td>
                            <td>{elem.fan_transactions.length}</td>
                            {/* <td>963</td> */}
                            <td>{elem.amount}</td>
                            {/* <td> $ {12000 + 963}</td> */}
                            <td>${elem.dalbum.price*elem.fan_transactions.length} </td>
                            {/* <td>${((12000 + 963 - 1500) * 10 / 100)}</td> */}
                        </tr>)}
                    </tbody>
                </table>

                <Pagination
                    changePage={this.changePage}  //function Call 
                    totalPages={this.state.totalPages}
                    limit={this.state.limit}
                    currentPage={this.state.pageNo}
                />

            </div>
        )
    };
}

export default Earnings
