import React from 'react'

class Pagination extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            numberOfPages: '',
            entryPerPage: 20,
            currentPage: 1
        };
        this.changePagePosition = this.changePagePosition.bind(this);
        this.changeLimit = this.changeLimit.bind(this);

    }
    componentWillReceiveProps() {
         
        this.setState({
            numberOfPages: this.props.totalPages,
            entryPerPage: this.props.limit,
            currentPage: this.props.currentPage
        })
        console.log("All Props" + this.props);
    }
    changePagePosition( pageNumber,thiss) {
        // debugger
        // if(pageNumber>this.state.numberOfPages)
        //     pageNumber = this.state.numberOfPages
        this.props.changePage(pageNumber,this.props.limit)
    }
    changeLimit(PerPage,thiss) {
        //  
        // this.setState({ entryPerPage: PerPage} , () => {
            this.props.changePage(this.props.currentPage, PerPage)
        // })
        // this.props.changePage(this.state.currentPage, PerPage)
    }
    render() {
        let arrayOfPageNumber = [];
        for (let i = 0; i < this.props.totalPages; i++) {
            arrayOfPageNumber.push(i + 1)
        }
        return (
            <nav aria-label="..." className="quick-links">
            <div className="row">
                <div className="col-md-6">
                    <h2 htmlFor="">Goto Page</h2>
                    <ul className="pagination">
                        {/* <li className="">
                            <span>
                                <span onClick={this.changePagePosition.bind(this, this.state.currentPage-1)} aria-hidden="true">&laquo;</span>
                            </span>
                        </li> */}
                        {arrayOfPageNumber.map(pageNumber =>
                            <li className="" onClick={this.changePagePosition.bind(this, pageNumber)}>
                                <span>{pageNumber}  <span className="sr-only" ></span></span>
                            </li>
                        )}
                        {/* <li className="">
                            <span>
                                <span onClick={this.changePagePosition.bind(this, this.state.currentPage+1)} aria-hidden="true">&raquo;</span>
                            </span>
                        </li> */}
                        {/* <hr /> */}
                    </ul> 
                </div>
                <div className="col-md-6 text-right">
                <h2 htmlFor="perpage">Entries Per Page</h2>
                <ul name="perpage" className="pagination">
                    <li> </li>
                    {/* <li> <span onClick={this.changeLimit.bind(this, 1)}>1 </span> </li> */}
                    <li> <span onClick={this.changeLimit.bind(this, 10)}>10 </span> </li>
                    <li> <span onClick={this.changeLimit.bind(this, 20)}>20 </span> </li>
                    <li> <span onClick={this.changeLimit.bind(this, 30)}>30 </span> </li>
                    <li> <span onClick={this.changeLimit.bind(this, 50)}>50 </span> </li>

                </ul>
            </div>
            </div>

            
            </nav>

        )
    };
}
export default Pagination
