import React, { Component } from 'react'
import { notify } from 'react-notify-toast';
import axios from 'axios';


class ChangePwd extends Component {
    constructor(props) {
        super(props);
        this.state = {
            password: '',
            newPassword: '',
            CnewPassword:'',
            errors :
                {
                    oldError:null,
                    newError:null,
                    ConfirmNewError:null
                },
            passwordChangedMessage : null,
            success : ''
            
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleClick = this.handleClick.bind(this)
    }
    cleanChit = false ;

    handleInputChange(event) {
         
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value,
            passwordChangedMessage : null 
        } , () =>
        this.validation(name) );
    }
    validation = (forName) => {
        var error = this.state.errors;
        if (forName === 'password')
        {
            if(!this.state.password){
                error.oldError = 'Old Password is required';
                this.cleanChit = false;
            }
            else {
            error.oldError = null;
            this.cleanChit = true;}
        }
        else if (forName === 'newPassword')
        {
            if(!this.state.newPassword){
                error.newError = 'New Password is required';
                this.cleanChit = false;
            }
            else if (this.state.newPassword.length < 6){
                error.newError = 'New Password length must be greater than 6'
                this.cleanChit = false;
            }
            else{
            error.newError = null;
            this.cleanChit = true;
            }
        }
        else if (forName === 'CnewPassword')
        {
            if(!this.state.newPassword){
                error.ConfirmNewError = 'Confirm Password is required';
                this.cleanChit = false;
            }
            else if (this.state.newPassword != this.state.CnewPassword)
            {
                error.ConfirmNewError = "Password didn't match";
                this.cleanChit = false;
            }
            else{
                error.ConfirmNewError = null;
                this.cleanChit = true;
            }
        }
        else {
        }
        this.setState({ errors : error})
    }

    handleClick() {
        var self = this;
        if (!this.cleanChit)
        {
            notify.show("Please fill the required(*) fields", 'error', 1500);
            return 69
        }
        var config = {
            headers: { 'Authorization': "Bearer " + localStorage.getItem("tokenAdmin") }
        };
        axios.post("/auth/super_admin/change_password", {
            password: this.state.password,
            new_password: this.state.newPassword,
        }, config)
            .then(function (response) {
                 
                if (response.data.success) {
                    notify.show('Password Changed', 'success', 1400);
                    self.setState({ passwordChangedMessage : "Password Changed !" ,
                    password: '',
                    newPassword: '',
                    CnewPassword:'',
                    success : true })

                }
                else {
                self.setState({ passwordChangedMessage : response.data.error,
                                success : false})

                    
                }
            })
            .catch(function (error) {
                notify.show(error.response.data.error, 'error', 1400);
                self.setState({ passwordChangedMessage : error.response.data.error ,
                                success : false})

            });
    }
    render() {
        return (
            <div className="container" id="page-wrapper">
                <div className="row">
                    <div className="col-md-4 col-md-offset-4">
                    {this.state.success && 
                    <p style={{ color: "green", textAlign : "center" }}>{this.state.passwordChangedMessage}</p>}
                    {!this.state.success && 
                    <p style={{ color: "red", textAlign : "center" }}>{this.state.passwordChangedMessage}</p>}

                        <div className="login-panel panel panel-default">
                            <div className="panel-heading">
                                <h3 className="panel-title">Change Password</h3>
                            </div>
                            <div className="panel-body">
                                {/* <form role="form"> */}
                                <form >
                                    <div className="form-group">
                                        <label> Old Password <span style={{ color: "red" }} > *</span></label>
                                        <input className="form-control"
                                            placeholder="Old Password"
                                            name="password"
                                            type="password"
                                            autoFocus
                                            autoComplete="true"
                                            value ={this.state.password}
                                            onChange={this.handleInputChange} />
                                        <span style={{ color: "red" }}>{this.state.errors.oldError}</span>

                                    </div>
                                    <div className="form-group">
                                        <label> New Password <span style={{ color: "red" }} > *</span></label>
                                        <input className="form-control"
                                            placeholder="Password"
                                            name="newPassword"
                                            type="password"
                                            value ={this.state.newPassword}
                                            onChange={this.handleInputChange} />                                
                                        <span style={{ color: "red" }}>{this.state.errors.newError}</span>
                                    </div>
                                    <div className="form-group">
                                        <label> Confirm New Password <span style={{ color: "red" }} > *</span></label>
                                        <input className="form-control"
                                            placeholder="Password"
                                            value ={this.state.CnewPassword}
                                            name="CnewPassword"
                                            type="password"
                                            onChange={this.handleInputChange} />
                                        <span style={{ color: "red" }}>{this.state.errors.ConfirmNewError}</span>
                                    </div>
                                </form >
                                {/* </form> */}
                                <button className="btn btn-lg btn-success btn-block" onClick={this.handleClick}> Change Password</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    };
}

export default ChangePwd
