import React, { Component } from 'react'
import axios from 'axios';
import Notifications, { notify } from 'react-notify-toast';
// import * as url from '../shared/routes.js'

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: {},
            errors: {
                email: '',
                password: ''
            },
            email: '',
            password: '',
            token: '',
            formIsValid: false,
            redirect: false,
            myError : null
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleValidation = this.handleValidation.bind(this);


    }
    handleInputChange(event) {
        // // 
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        // 
        if(name != 'email')
        {
        this.setState({
            [name]: value,
            myError : null
        } , () =>  this.handleValidation(name) );
        }
        else {
            this.setState({
                [name]: value,
                myError : null

            });
        }

    }
    handleValidation(fieldName) {
        
        if (fieldName === 'email')
        {
            var error = this.state.errors;

            if (this.state.email == null || this.state.email == '') {
                // 
                this.state.formIsValid = false;
                error.email = "E-Mail is Empty"
            }
            else if (!this.state.email.includes('@') || !this.state.email.includes('.') ) {
                this.state.formIsValid = false;
                error.email = "E-Mail is invalid"
            }
            else {
                error.email = null;
                this.state.formIsValid = true;
            }
            this.setState({ errors : error})
        }
        else if (fieldName === 'password')
        {
            var error = this.state.errors;
            if (this.state.password == null || this.state.password == '') {
                // 
                this.state.formIsValid = false;
                // this.state.errors["password"] = "Cannot be empty";
                error.password = "Password is Empty"; 
            }
            else {
                error.password = null;
                this.state.formIsValid = true;
            }
            this.setState({errors : error})
        }
    }

    handleClick(e) {
        var self = this ;
        this.handleValidation();
        if (!this.state.formIsValid) {
            notify.show('Invalid Email or Password','error',1400);            
        }
        else {

            axios.post("/auth/super_admin/login", {
                email: this.state.email,
                password: this.state.password
            })
                .then(function (response) {
                    notify.show('Login Successfull !','success');
                    localStorage.setItem("tokenAdmin", response.data.data.token);

                    if (response.data.success) {
                        window.location.href = "/admin/genre"
                    }
                })
                .catch(function (error) {
                    self.setState({ myError : 'Either your Email or Password is incorrect.' })
                    notify.show('Either your Email or Password is incorrect.','error',1500);
                    console.log(error);
                });
        }
    }
    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-md-4 col-md-offset-4">
                        <div className="login-panel panel panel-default">
                            <div className="panel-heading">
                                <h3 className="panel-title">Please Sign In</h3>
                            </div>
                            <div className="panel-body">
                            <span style={{ color: "red" }}>{this.state.myError}</span>
                            
                                {/* <form role="form"> */}
                                <fieldset>
                                    <div className="form-group">
                                        <input className="form-control"
                                            placeholder="E-mail"
                                            name="email"
                                            type="email"
                                            autoFocus
                                            autoComplete="true"
                                            onChange={this.handleInputChange}
                                            onBlur = { () => this.handleValidation ('email')} />
                                        <span style={{ color: "red" }}>{this.state.errors["email"]}</span>

                                    </div>
                                    <div className="form-group">
                                        <input className="form-control"
                                            placeholder="Password"
                                            name="password"
                                            type="password"
                                            onChange={this.handleInputChange} />
                                        <span style={{ color: "red" }}>{this.state.errors["password"]}</span>

                                    </div>
                                    <div className="checkbox">
                                        <label>
                                            <input name="remember" type="checkbox" value="Remember Me" />Remember Me
                                </label>
                                    </div>
                                </fieldset>
                                {/* </form> */}
                                <button className="btn btn-lg btn-success btn-block" onClick={this.handleClick.bind(this)}> Login</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
// module.exports = () => <Toaster />;
export default Login
