import React, { Component } from 'react';
import axios from 'axios';
import $ from "jquery";
import { notify } from 'react-notify-toast';
import Pagination from './pagination';
import { isLoggedIn } from './AuthGuard';

// var $ = require("jquery");
// import * as url from  '../shared/routes.js'
// import BasicRoute from '../shared/routes.js'

class BlogCategory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            newGenreName: "",
            newGenreDescription: "",
            isActive: '',
            showEditModel: false,
            editGenreName: "",
            editGenreDescription: "",
            genreEditId: '',
            errors: '',
            formIsValid: false,
            pageNo: 1,
            limit: 20,
            totalPages: '',
        };
        this.componentWillMount = this.componentWillMount.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.addNewGenre = this.addNewGenre.bind(this);
        this.showModal = this.showModal.bind(this);
        this.editGenre = this.editGenre.bind(this);
        this.handleValidation = this.handleValidation.bind(this);
        this.IntitialDataCall = this.IntitialDataCall.bind(this);
        this.changePage = this.changePage.bind(this)

    }
    componentWillMount() {
        // this.isLoggedIn();
        this.IntitialDataCall();
        // if (!localStorage.getItem('tokenAdmin')) {
        //     // code goes here 
        //     window.location.href = "/";
        //     notify.show('Invalid Entry', 'danger', 1400);
        // }
    }
    IntitialDataCall() {
        var self = this;
        var config = {
            headers: { 'Authorization': "Bearer " + localStorage.getItem("tokenAdmin") }
        };
        // this.setState({data:[1,2,3]})
        axios.get("/auth/super_admin/getblogcategory" + "?page=" + this.state.pageNo + "&limit=" + this.state.limit, config)
            .then(function (response) {
                if (response.data.data.result) {

                    // notify.show('All Entries Loded', 'success', 1400);
                    console.log("This is Response ", response.data.data);
                    self.setState({
                        data: response.data.data.result,
                        totalPages: response.data.data.pages
                    })
                }
            })
            .catch(function (error) {
                notify.show('Network Connection Error', 'error', 1400);
                console.log(error);
            });
    }
    handleInputChange(event) {

        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });

    }
    handleValidation(addOREdit) {
        if (addOREdit == 'new') {
            if (this.state.newGenreName == null || this.state.newGenreName == '') {

                this.setState({ formIsValid: false, })
                this.setState({ errors: "Field is required" })
            }
            else {
                //last cursor
                this.state.errors = null;
                this.state.formIsValid = true;
            }
        }
        else {
            if (this.state.editGenreName == null || this.state.editGenreName == '') {

                this.setState({ formIsValid: false, })
                this.setState({ errors: "Field is required" })
            }
            else {
                //last cursor
                this.state.errors = null;
                this.state.formIsValid = true;
            }
        }


        console.log("All States", this.state);
        return this.state.formIsValid;

    }

    showModal = (selectedRow) => {
        this.setState({ errors: null })
        this.setState({
            editGenreName: selectedRow.name,
            editGenreDescription: selectedRow.description,
            genreEditId: selectedRow.blog_category_id,
            isActive: selectedRow.is_active
        });

    };

    addNewGenre() {

        if (!this.handleValidation("new")) {
            return 2;
        }
        var self = this;
        var config = {
            headers: { 'Authorization': "Bearer " + localStorage.getItem("tokenAdmin") }
        };
        let activeState = this.state.isActive ? 1 : 0;
        axios.post("/auth/super_admin/addblogCategory", {
            name: this.state.newGenreName,
            description: this.state.newGenreDescription,
            is_active: activeState
        }, config)
            .then(function (response) {
                if (response.data.success) {
                    window.$('#exampleModalCenter').modal('hide');
                    notify.show('Genre Added', 'success', 1400);

                    self.setState({
                        errors: null,
                        newGenreName: null,
                        newGenreDescription: null
                    })
                    self.IntitialDataCall();
                }

                else {
                    notify.show('Error Occurred', 'error', 1400);
                }
                notify.show('Error Occurred', 'error', 1400);

                console.log("List of Genres", response);
            })
            .catch(function (error) {
                notify.show('Network Error Genre not Added', 'error', 1400);
                console.log(error);
            });

    }
    editGenre() {

        if (!this.handleValidation("edit")) {
            return 2;
        }
        var self = this;
        var config = {
            headers: { 'Authorization': "Bearer " + localStorage.getItem("tokenAdmin") }
        };
        let activeState = this.state.isActive ? 1 : 0;
        axios.post("/auth/super_admin/updateblogCategory", {
            name: this.state.editGenreName,
            description: this.state.editGenreDescription,
            is_active: activeState,
            blog_category_id: this.state.genreEditId
        }, config)
            .then(function (response) {

                if (response.data.success) {
                    notify.show('Genre Edited', 'success', 1400);
                    window.$('#editModal').modal('hide')
                    self.IntitialDataCall();
                }
            })
            .catch(function (error) {
                notify.show('Error Occurred', 'error', 1400);
            });
    }

    changePage(pageNumber, limitEntry) {

        this.setState({
            pageNo: pageNumber,
            limit: limitEntry
        }, () => {
            this.IntitialDataCall();
        });
    }
    render() {
        return (
            <div id="page-wrapper">
                <div className="row">
                    <div className="col-md-6">
                        <h1>Blog Category</h1>
                    </div>
                    <div className="col-md-6">
                        <button type="button" className=" fa fa-plus btn btn-primary pull-right" data-toggle="modal" data-target="#exampleModalCenter">
                            Add Blog Category
                        </button>
                    </div>
                </div>
                <table width="100%" className="table table-striped table-bordered table-hover" id="dataTables-example">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Description</th>
                            <th>Status</th>
                            <th>Date Created</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.data.map(row =>
                            <tr className="odd gradeX">
                                <td>{row.name}</td>
                                <td>{row.description}</td>
                                {(row.is_active === 0 || row.is_active === null) &&
                                    <td> Not Active </td>
                                }
                                {row.is_active === 1 &&
                                    <td>  Active </td>
                                }
                                <td>{row.created_at.split("T")[0]}</td>
                                <td className="center">
                                    <ul className="list-inline">
                                        <li><i className=" fa fa-edit" onClick={() => this.showModal(row)} data-toggle="modal" data-target="#editModal" > </i></li>
                                    </ul>
                                </td>
                            </tr>)}
                    </tbody>
                </table>

                <Pagination
                    changePage={this.changePage}  //function Call 
                    totalPages={this.state.totalPages}
                    limit={this.state.limit}
                    currentPage={this.state.pageNo}
                />
                {/* Add Modal  */}
                <div className="modal fade" id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLongTitle">Add Genre</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <label htmlFor="newGenreName" >New Name <span style={{ color: "red" }} > *</span></label>
                                <div className="form-group">
                                    <input className="form-control"
                                        placeholder="New Genre Name"
                                        name="newGenreName"
                                        type="text"
                                        autoFocus
                                        autoComplete="true"
                                        onChange={this.handleInputChange} />
                                    <span style={{ color: "red" }} > {this.state.errors}</span>

                                </div>

                                <label htmlFor="newGenreDescription">Description</label>
                                <div className="form-group">
                                    <input className="form-control"
                                        placeholder="Description"
                                        name="newGenreDescription"
                                        type="text"
                                        onChange={this.handleInputChange} />
                                </div>
                                <div className="form-group">
                                    <span className="switch switch-sm">
                                        <input type="checkbox"
                                            className="switch"
                                            id="switch-sm"
                                            name="isActive"
                                            onChange={this.handleInputChange}
                                        />
                                        <label htmlFor="switch-sm">Active</label>
                                    </span>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button type="button" className="btn btn-primary" onClick={this.addNewGenre} > Add</button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Edit Modal */}
                <div className="modal fade" id="editModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLongTitle">Edit Category</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <label htmlFor="editGenreName" >Edit Genre Name
                            <span style={{ color: "red" }} > *</span>
                                </label>
                                <div className="form-group">
                                    <input className="form-control"
                                        placeholder=" Genre Name"
                                        name="editGenreName"
                                        type="text"
                                        autoFocus
                                        autoComplete="true"
                                        onChange={this.handleInputChange}
                                        value={this.state.editGenreName}
                                    />
                                    <span style={{ color: "red" }} > {this.state.errors}</span>

                                </div>
                                <label htmlFor="editGenreDescription">Description</label>
                                <div className="form-group">
                                    <input className="form-control"
                                        placeholder="Description"
                                        name="editGenreDescription"
                                        type="text"
                                        onChange={this.handleInputChange}
                                        value={this.state.editGenreDescription}
                                    />
                                </div>
                                <div className="form-group">
                                    <span className="switch switch-sm">
                                        <input type="checkbox" className="switch" id="switch-sm" name="isActive" onChange={this.handleInputChange} checked={this.state.isActive} />
                                        <label htmlFor="switch-sm">Active</label>
                                    </span>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button type="button" className="btn btn-primary" onClick={this.editGenre} >Update Genre</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    };
}
export default BlogCategory
