import React, { Component } from 'react';
import axios from 'axios';
import { notify } from 'react-notify-toast';

class Roles extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            editName: "",
            editDescription: "",
            roleEditId: '',
            errors :'',
            formIsValid : ''
        };
        this.componentWillMount = this.componentWillMount.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.showModal = this.showModal.bind(this);
        this.editEntry = this.editEntry.bind(this);
    }
    componentWillMount() {
        var self = this;
        // this.setState({data:[1,2,3]})
        axios.get("/primary_role?page=1&limit=20")
            .then(function (response) {
                if (response.data.data.result) {
                    // notify.show('All Entries Loded', 'success', 1400);
                    self.setState({ data: response.data.data.result })
                }
            }) 
            .catch(function (error) {
                notify.show('Error Occurred', 'error', 1400);
                console.log(error);
            });
    }
    handleInputChange(event) {
         
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    showModal = (selectedRow) => {
        this.setState({errors : null})         
        this.setState({
            editName: selectedRow.name,
            editDescription: selectedRow.description,
            roleEditId: selectedRow.primary_role_id
        });
        console.log("State Values", this.state);

    };

    handleValidation() {

        if (this.state.editName === null || this.state.editName === '') {
            this.setState({ formIsValid: false, })
            this.setState({ errors: "Field is required" })
        }
        else {
            this.setState({ formIsValid: true, })
            this.setState({ errors: null })
        }
        console.log("All States", this.state);
        return this.state.formIsValid;

    }
    editEntry() {

        if (!this.handleValidation()) {
            return 2;
        }

        var self = this;
        var config = {
            headers: { 'Authorization': "Bearer " + localStorage.getItem("tokenAdmin") }
        };
        axios.put("/primary_role", {
            name: this.state.editName,
            description: this.state.editDescription,
            is_active: 1,
            id: this.state.roleEditId
        }, config)
            .then(function (response) {
                if (response.data.success) {
                    window.$('#editModal').modal('hide');
                    notify.show('Role Updated', 'success', 1400);
                    self.componentWillMount();
                }
            })
            .catch(function (error) {
                notify.show('Error Occurred', 'error', 1400);
            });
    }
    render() {
        return (
            <div id="page-wrapper">
                <div className="row">
                    <div className="col-md-6">
                        <h1>Roles Management</h1>
                    </div>

                </div>
                <table width="100%" className="table table-striped table-bordered table-hover" id="dataTables-example">
                    <thead>
                        <tr>
                            <th>Role </th>
                            <th>Description</th>
                            {/* <th>Active</th> */}
                            <th>Date Created</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.data.map(row =>
                            <tr className="odd gradeX">
                                <td>{row.name}</td>
                                <td>{row.description}</td>
                                {/* {(row.is_active === 0 || row.is_active === null) &&
                                    <td> Not Active </td>
                                }
                                {row.is_active === 1 &&
                                    <td>  Active </td>
                                } */}
                                <td>{row.created_at.split("T")[0]}</td>                                
                                <td className="center">
                                    <ul className="list-inline">
                                        <li><i className=" fa fa-edit" onClick={() => this.showModal(row)} data-toggle="modal" data-target="#editModal" > </i></li>
                                    </ul>
                                </td>
                            </tr>)}
                    </tbody>
                </table>
                {/* Edit Modal */}
                <div className="modal fade" id="editModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLongTitle">Edit Roles</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                            <label htmlFor="editName">Edit Role Name <span style={{ color: "red" }} > *</span></label>
                                <div className="form-group">
                                    <input className="form-control"
                                        placeholder="Role Name"
                                        name="editName"
                                        type="text"
                                        autoFocus
                                        autoComplete="true"
                                        onChange={this.handleInputChange}
                                        value={this.state.editName}
                                    />
                                    <span style={{ color: "red" }} className ="error" > {this.state.errors}</span>

                                </div>
                                <label htmlFor="editDescription">Description</label>
                                <div className="form-group">
                                    <input className="form-control"
                                        placeholder="Description"
                                        name="editDescription"
                                        type="text"
                                        onChange={this.handleInputChange}
                                        value={this.state.editDescription}
                                    />

                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button type="button" className="btn btn-primary" onClick={this.editEntry} >Update </button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    };
}
export default Roles
