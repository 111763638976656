import React from 'react'
import { BrowserRouter,Route } from 'react-router-dom'
// import Home from '../home'
import Login from '../login'
import Sidebar from '../sideBar'
import Dashboard from '../dashboard'
import SubGenre from '../dashboard/sub_genre';
import Earnings from '../dashboard/earnings';
import Genre from '../dashboard/genre';
import Instruments from '../dashboard/instruments';
import Users from '../dashboard/users';
import Roles from '../dashboard/roles';
import Fan from '../users/fan';
import Admin from '../users/admin';
import Musicians from '../dashboard/musicians';
import Notifications from 'react-notify-toast';
import axios from 'axios';
import ChangePwd from '../admin/changePwd';
import My404Component from './oop'
import Blog from '../dashboard/blog';
import BlogCategory from '../dashboard/blogCategory';
import EditBlog from '../editBlog';


//axios.defaults.baseURL = "http://api.dalbumx.com/backend";
// axios.defaults.baseURL = "http://192.168.0.221:4000";
//axios.defaults.baseURL = "http://104.248.224.164:5000";
axios.defaults.baseURL = "https://api.dalbumx.com";

axios.interceptors.response.use(function (response) {
  if (response.data.message == "jwt expired" || response.data.message == "jwt malformed") {
    localStorage.clear();
   window.location = '/';
  }
  else {
    return response;
  }
  // Do something with response data
}, function (error) {
  // Do something with response error
  return Promise.reject(error);
});

const App = () => (
  <BrowserRouter>
    <div>

      <main>
        {/* <Route path='*' exact={true} component={My404Component} /> */}

        <Route exact path="/" component={Login} />
        <Route path="/admin" component={Sidebar} />
        <Route exact path="/admin/dashboard" component={Dashboard} />
        <Route exact path="/admin/genre" component={Genre} />
        <Route exact path="/admin/earnings" component={Earnings} />
        <Route exact path="/admin/musician" component={Musicians} />
        <Route exact path="/admin/instruments" component={Instruments} />
        <Route exact path="/admin/users" component={Users} />
        <Route exact path="/admin/sub_genre" component={SubGenre} />
        <Route exact path="/admin/role" component={Roles} />
        <Route exact path="/admin/fanManagement" component={Fan} />
        <Route exact path="/admin/adminManagement" component={Admin} />
        <Route exact path="/admin/blogManagement" component={Blog} />
        <Route exact path="/admin/editBlog" component={EditBlog} />
        <Route exact path="/admin/blogCategoryManagement" component={BlogCategory} />
        <Route exact path="/admin/changePassword" component={ChangePwd} />

        {/* <Route path='*' component={Login} /> */}

      </main>

      <Notifications options={{zIndex:"2000"}}/>
    </div>
  </BrowserRouter>
)



export default App
