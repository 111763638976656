import React from 'react'
import './pagination.css'

const List = (props) => {
    
    return (

        <table width="100%" className="table table-striped table-bordered table-hover" id="dataTables-example">
            <thead>
                <tr className="odd gradeX">
                    {props.header.map(row =>
                         {
                            if(row === 'username')
                            {
                                return ( <th> User Name </th>);
                            }
                            else if(row === 'email')
                            {
                                return ( <th> Email</th> );
                            }
                            else if(row === 'is_active')
                            {
                                return ( <th> Status </th> );
                            }
                            else if(row === 'is_verified')
                            {
                                return ( <th> Email Verified </th> );
                            }
                            else if(row === 'phone')
                            {
                                return ( <th> Contact Number </th> );
                            }
                            else if(row === 'firstname')
                            {
                                return ( <th> First Name </th> );
                            }
                            else if(row === 'lastname')
                            {
                                return ( <th> Last Name </th> );
                            }
                            else 
                            {
                                return <th>{row}</th>;    
                            }    
                        })}
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                {props.data.map(row =>
                    <tr className="odd gradeX">
                        {props.header.map(key =>{ 
                            if(row[key] === 1 ){
                              return (  <td> Active </td>)
                            }
                            else if(row[key] === 0 ){
                                return ( <td> Inactive </td>)
                            }
                            else if (key === 'created_at'){
                                return (<td>{row[key].split('T')[0] } </td>)
                            }                            
                            else{
                                return ( <td> {row[key]} </td>  )
                            }                       
                        })}
                        <td>
                            <ul className="list-inline">
                                <li>
                                    <a>
                                        <i className=" fa fa-edit" onClick={() => props.onEdit(row)}></i>
                                    </a>
                                </li>
                            </ul>
                        </td>
                    </tr>)}
            </tbody>
        </table>
    )
}

const Pagination = (props) => {
    const totalPages = props.totalPages
    const currentPage = props.currentPage
    let pageNumber = []
    let i = currentPage-2
    if(i<=2){
        i = 1
    }
    // let j = currentPage+2
    // if (totalPages<=j){
    //     j = totalPages
    // }
    let  j = i+4
    if(j > totalPages) {
        j = totalPages
    }

    for (let k=i ; k <= 5; k++) {
        pageNumber.push(k)
    }

    return (
        <div class="container">
            <ul class="pagination">
            {pageNumber.map(j =>  <li><a onClick={props.pageChange.bind(this, j)} value={j}>{j}</a></li>)}
                
            </ul>
        </div>
    )
}


export {
    List,
    Pagination
}

