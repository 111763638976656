import React from 'react'
import MainDashboard from './dashboard'

const Dashboard = () => (
    <div>
        <main>
            <div id="page-wrapper">
                <MainDashboard 
                    isLoggin = {true}
                />
            </div>
        </main>

    </div>
)
export default Dashboard
