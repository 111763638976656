import React from 'react'

class Authguard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };


    }

}

export default Authguard
