import React, { Component } from 'react';
import axios from 'axios';
// import * as url from  '../shared/routes.js'
import { notify } from 'react-notify-toast';
import Pagination from './pagination';

class SubGenre extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            genre: [],
            newGenreName: "",
            newGenreDescription: "",
            showEditModel: false,
            editGenreName: "",
            editGenreDescription: "",
            genreEditId: '',
            selectGenre: '',
            pageNo: 1,
            limit: 20,
            totalPages: '',
            loadPagination: false,
            formIsValid: false,
            errors: "",
            errorsD: "",
            isActive: ''
        };
        this.componentWillMount = this.componentWillMount.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.addNewGenre = this.addNewGenre.bind(this);
        this.showModal = this.showModal.bind(this);
        this.editGenre = this.editGenre.bind(this);
        this.getIntialData = this.getIntialData.bind(this);
        this.getGenre = this.getGenre.bind(this);
        this.changePage = this.changePage.bind(this)
    }
    componentWillMount() {
        this.getIntialData();
        this.getGenre();
    }


    getIntialData() {
        var self = this;
        console.log(this.state);
         
        axios.get("/sub_genre" + "?page=" + this.state.pageNo + "&limit=" + this.state.limit)
            .then(function (response) {
                if (response.data.data.result) {
                    // notify.show('All Entries Loded', 'success', 1400);
                    self.setState({
                        data: response.data.data.result,
                        loadPagination: true,
                        totalPages: response.data.data.pages
                    });
                     
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    handleValidation(addOREdit) {
        if (addOREdit == 'new') {
            if (this.state.newGenreName === null || this.state.newGenreName === '') {
                 
                this.setState({ formIsValid: false, })
                this.setState({ errors: "Field is required" })
            }
            else if (this.state.selectGenre === null || this.state.selectGenre === '') {
                this.setState({ formIsValid: false, })
                this.setState({ errorsD: "Field is required" })
            }
            else {
                //last cursor
                this.state.errors = null;
                this.state.formIsValid = true;
            }
        }
        else {
            if (this.state.editGenreName == null || this.state.editGenreName == '') {
                 
                this.setState({ formIsValid: false, })
                this.setState({ errors: "Field is required" })
            }
            else if (this.state.selectGenre === null || this.state.selectGenre === '') {
                this.setState({ formIsValid: false, })
                this.setState({ errorsD: "Field is required" })
            }
            else {
                //last cursor
                this.state.errors = null;
                this.state.formIsValid = true;
            }
        }


        console.log("All States", this.state);
        return this.state.formIsValid;

    }

    getGenre() {
        var self = this;
        axios.get("/genre" + "?page=1&limit=200")
            .then(function (response) {
                if (response) {
                    console.log("This is Response for genre ", response.data.data);
                    self.setState({ genre: response.data.data.result })
                }
            })
            .catch(function (error) {
                notify.show('Error Occurred', 'error', 1400);
            });
    }
    handleInputChange(event) {
         
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });

    }

    changePage(pageNumber, limitEntry) {
         
        this.setState({
            pageNo: pageNumber,
            limit: limitEntry
        }, () => {
            this.getIntialData();
        });
    }

    showModal = (selectedRow) => {
        this.setState({errors : null})
        this.setState({
            editGenreName: selectedRow.name,
            editGenreDescription: selectedRow.description,
            genreEditId: selectedRow.sub_genre_id,
            selectGenre: selectedRow.genre.name,
            isActive: selectedRow.is_active
        });
    };

    searchId = (nameKey, myArray) => {
         
        for (var i = 0; i < myArray.length; i++) {
            if (myArray[i].name === nameKey) {
                return myArray[i];
            }
        }
    }
    addNewGenre() {
        if (!this.handleValidation("new")) {
            return 2;
        }
         
        var self = this;
        let genreId = this.searchId(this.state.selectGenre, this.state.genre).genre_id;
        var config = {
            headers: { 'Authorization': "Bearer " + localStorage.getItem("tokenAdmin") }
        };
        let activeState = this.state.isActive ? 1 : 0;
        axios.post("/sub_genre", {
            name: this.state.newGenreName,
            description: this.state.newGenreDescription,
            genre_id: genreId,
            is_active: activeState
        }, config)
            .then(function (response) {
                 
                if (response.data.success) {
                    window.$('#exampleModalCenter').modal('hide');
                    notify.show('Sub Genre Added', 'success', 1400);
                    self.getIntialData();
                }
                else {
                    notify.show(response.data.message, 'error', 1400);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    editGenre() {
        if (!this.handleValidation("edit")) {
            return 2;
        }
        var self = this;
        let genreId = this.searchId(this.state.selectGenre, this.state.genre).genre_id;
        var config = {
            headers: { 'Authorization': "Bearer " + localStorage.getItem("tokenAdmin") }
        };
        axios.put("/sub_genre", {
            name: this.state.editGenreName,
            description: this.state.editGenreDescription,
            is_active: this.state.isActive,
            id: this.state.genreEditId,
            genre_id: genreId
        }, config)
            .then(function (response) {
                if (response.data.success) {
                    window.$('#editModal').modal('hide');
                    notify.show('Sub Genre Edited', 'success', 1400);
                    self.getIntialData();
                }
                else {
                    notify.show('Error Occurred', 'error', 1400);

                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    render() {
        let genres = this.state.genre;
        let optionItems = genres.map((genre) =>
            <option key={genre.genre_id}>{genre.name}</option>
        );
        return (
            <div id="page-wrapper">
                <div className="row">
                    <div className="col-md-6">
                        <h1>Sub-Genre</h1>
                    </div>
                    <div className="col-md-6">
                        <button type="button" className=" fa fa-plus btn btn-primary pull-right" data-toggle="modal" data-target="#exampleModalCenter">
                            Add Sub-Genre
                        </button>
                    </div>
                </div>
                <table width="100%" className="table table-striped table-bordered table-hover" id="dataTables-example">
                    <thead>
                        <tr>
                            <th>Sub-Genre Name</th>
                            <th>Description</th>
                            <th>Active</th>
                            <th>Genre Associated</th>
                            <th>Date Created</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.data.map(row =>
                            <tr className="odd gradeX">
                                <td>{row.name}</td>
                                <td>{row.description}</td>
                                {(row.is_active === 0 || row.is_active === null) &&
                                    <td> Not Active </td>
                                }
                                {row.is_active === 1 &&
                                    <td>  Active </td>
                                }
                                
                                <td>{row.genre.name} </td>
                                <td>{row.created_at.split("T")[0]}</td>
                                
                                <td className="center">
                                    <ul className="list-inline">
                                        <li><i className=" fa fa-edit" onClick={() => this.showModal(row)} data-toggle="modal" data-target="#editModal" > </i></li>
                                    </ul>
                                </td>
                            </tr>)}
                    </tbody>
                </table>

                <Pagination
                    changePage={this.changePage}  //function Call 
                    totalPages={this.state.totalPages}
                    limit={this.state.limit}
                    currentPage={this.state.pageNo}
                />

                {/* Add Modal  */}
                <div className="modal fade" id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLongTitle">Add Sub-Genre</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                            <label htmlFor="newGenreName">New SubGenre Name <span style={{ color: "red" }} > *</span></label>
                                <div className="form-group">
                                    <input className="form-control"
                                        placeholder="New SubGenre Name"
                                        name="newGenreName"
                                        type="text"
                                        autoFocus
                                        autoComplete="true"
                                        onChange={this.handleInputChange} />
                                    <span style={{ color: "red" }} > {this.state.errors}</span>
                                </div>
                                <label htmlFor="newGenreDescription">Description</label>
                                <div className="form-group">
                                    <input className="form-control"
                                        placeholder="Description"
                                        name="newGenreDescription"
                                        type="text"
                                        onChange={this.handleInputChange} />
                                </div>
                                <div>
                                <label htmlFor="selectGenre">Select Genre</label>
                                    <select className="form-control" onChange={this.handleInputChange} name="selectGenre" >
                                        <option> Select One</option>
                                        {optionItems}
                                    </select>
                                    <span style={{ color: "red" }} > {this.state.errorsD}</span>
                                </div>
                                <div className="form-group">
                                    <span className="switch switch-sm">
                                        <input type="checkbox" className="switch" id="switch-sm" name="isActive" onChange={this.handleInputChange} />
                                        <label htmlFor="switch-sm">Active</label>
                                    </span>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button type="button" className="btn btn-primary" onClick={this.addNewGenre} > Add</button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Edit Modal */}
                <div className="modal fade" id="editModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLongTitle">Edit Sub-Genre </h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                            <label htmlFor="editGenreName">SubGenre Name <span style={{ color: "red" }} > *</span></label>
                                <div className="form-group">
                                    <input className="form-control"
                                        placeholder="New SubGenre Name"
                                        name="editGenreName"
                                        type="text"
                                        autoFocus
                                        autoComplete="true"
                                        onChange={this.handleInputChange}
                                        value={this.state.editGenreName}
                                    />
                                    <span style={{ color: "red" }} > {this.state.errors}</span>

                                </div>
                                <label htmlFor="editGenreDescription">Description</label>
                                <div className="form-group">
                                    <input className="form-control"
                                        placeholder="Description"
                                        name="editGenreDescription"
                                        type="text"
                                        onChange={this.handleInputChange}
                                        value={this.state.editGenreDescription}
                                    />
                                </div>
                                <label htmlFor="selectGenre">Select Genre</label>
                                <select className="form-control" onChange={this.handleInputChange} name="selectGenre" >
                                    <option value={this.state.selectGenre} > {this.state.selectGenre}</option>
                                    {optionItems}
                                </select>
                                <span style={{ color: "red" }} > {this.state.errorsD}</span>

                                <div className="form-group">
                                    <span className="switch switch-sm">
                                        <input type="checkbox" className="switch" id="switch-sm" name="isActive" onChange={this.handleInputChange} checked={this.state.isActive} />
                                        <label htmlFor="switch-sm">Active</label>
                                    </span>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button type="button" className="btn btn-primary" onClick={this.editGenre} >Update </button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    };
}
export default SubGenre
