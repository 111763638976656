import React, { Component } from 'react';
import axios from 'axios';
import { notify } from 'react-notify-toast';
import { List } from './tablelist';
import Pagination from '../dashboard/pagination';
import isBlank from './validation';


class Fan extends Component {
    constructor(props) {
        super(props);
        this.totalPages = 9
        this.header = ['username', 'email', 'is_active','created_at']
        this.modalData = {}
        this.state = {
            errors:{},
            selectedrow: {},
            // currentPage: 8,
            data: [],
            totalPages:'',
            limit:10,
            pageNo:1,
            field : 'username',
            searchFor :''
        };
        this.componentWillMount = this.componentWillMount.bind(this);
        this.editFan = this.editFan.bind(this);
        this.updateFan = this.updateFan.bind(this);
        this.onChange = this.onChange.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }
    cleanChit = true;
    componentWillMount() {
        this.IntitialDataCall()
    }
    IntitialDataCall() {
        // debugger
        var self = this;
        var config = {
            headers: { 'Authorization': "Bearer " + localStorage.getItem("tokenAdmin") }
        };
        axios.get("/auth/super_admin/fan?page="+this.state.pageNo+"&limit="+ this.state.limit + "&field=" +this.state.field + "&search=" + this.state.searchFor, config)
            .then(function (response) {
                if (response.data.data.result) {
                    // notify.show('All Entries Loded', 'success', 1400);
                    self.setState({ data: response.data.data.result,totalPages: response.data.data.pages })
                    console.log("Fan Details", response.data.data.result);

                }
            })
            .catch(function (error) {
                notify.show('Network Connection Error', 'error', 1400);
                console.log(error);
            });
    }

    closeModal(){
        this.IntitialDataCall()
    }

    editFan(data) {
        this.setState({
            selectedrow : data
        })
        // alert(data.email)
        // alert(this.selectedrow.is_active)
        window.$("#editModal").modal();
    }

    changePage = (pageNumber, limitEntry) => {

        this.setState({
            pageNo: pageNumber,
            limit: limitEntry
        }, () => {
            this.componentWillMount();
        });
    }

    onChange(target) {
        var selectedrow = this.state.selectedrow
        selectedrow[target.name] = target.value 
        this.setState({
            selectedrow: selectedrow,
       });
       if (target.name === 'firstname' || target.name === 'city' || target.name === 'state'|| target.name === 'country' || target.name === 'phone')
       this.Validite(target.name);
    }
    Validite = (fieldName) =>{
        if (isBlank(this.state.selectedrow[fieldName]))
            {
                var error = this.state.errors
                error[fieldName] = 'Required' 
                this.setState({
                    errors : error
                })
                this.cleanChit = false;
            }
            if (!isBlank(this.state.selectedrow[fieldName]))
            {
                var error = this.state.errors
                error[fieldName] = null 
                this.setState({
                    errors : error
                })
                this.cleanChit = true;
            }
    }
    updateFan() {
        if (!this.cleanChit)
        {
            return 6;
        }
        var self = this;
        var config = {
            headers: { 'Authorization': "Bearer " + localStorage.getItem("tokenAdmin") }
        };
        var data = {
            id: self.state.selectedrow.user_id,
            is_active: self.state.selectedrow.is_active,
            is_verified: self.state.selectedrow.is_verified,
            email:self.state.selectedrow.email,
            city:self.state.selectedrow.city,
            state:self.state.selectedrow.state,
            country:self.state.selectedrow.country,
            password:self.state.selectedrow.password
        }
        axios.put("/auth/super_admin/fan", data, config)
            .then(function (response) {
                if (response.data.success) {
                    self.IntitialDataCall()
                    window.$('#editModal').modal('toggle');
                }
            })
            .catch(function (error) {
                notify.show('Network Connection Error', 'error', 1400);
                console.log(error);
            });
    }

    pageChange(data) {
        console.log(data)
    }
    searchIt = () =>
    {

    }
    render() {
        return (
            <div id="page-wrapper">
                <div className="row">
                    <div className="col-md-6">
                        <h2>Fan</h2>
                    </div>
                    <div className="col-md-6">
                        <div className="custom-search-wrapper pull-right">
                        <ul class="list-inline">
                        <li><label htmlFor ="search" > Search By </label></li>
                        <li>
                            <select className="form-control" onChange = { (e) => {this.setState({ field : e.target.value })} }> 
                                {this.header.map  ( element =>
                            <>{element != 'is_active' && element != 'created_at' &&
                            <option>{element}</option>}
                            </>
                                )}
                            </select>
                        </li>
                        <li>
                        <input className="form-control" type = "text" name="searchBar" onChange={ (e) =>{this.setState ({ searchFor : e.target.value } )}} />
                        </li>
                        <li>
                            <span onClick={(e) => this.IntitialDataCall(e)}><i class="fa fa-search"></i></span>
                        </li>
                        </ul>
                    
                        </div>
                    </div>
                    
                </div>
                
                <List
                    header={this.header}
                    data={this.state.data}
                    onEdit={this.editFan}
                />

                <div className="modal fade" id="editModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLongTitle">Edit</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="form-group">
                                <label for="email">Email</label>
                                <input className="form-control"
                                        placeholder="Email"
                                        name="email"
                                        type="text"
                                        autoComplete="false"
                                        disabled
                                        value={this.state.selectedrow.email}
                                         />
                                </div>
                                <div className="form-group">
                                <label for="password">Change Password</label>
                                <input className="form-control"
                                        placeholder="Password"
                                        name="password"
                                        type="text"
                                        autoComplete="false"
                                        onChange={e => this.onChange(e.target)}
                                        // value={this.state.selectedrow.password}
                                         />
                                </div>
                                <div className="form-group">
                                <label for="City">City</label>
                                <input className="form-control"
                                        placeholder="City"
                                        name="city"
                                        type="text"
                                        autoComplete="false"
                                        onChange={e => this.onChange(e.target)}
                                        value={this.state.selectedrow.city}
                                         />
                                     <span style={{ color: "red" }} > {this.state.errors.city}</span>

                                </div>
                                <div className="form-group">
                                <label for="State">State</label>
                                <input className="form-control"
                                        placeholder="State"
                                        name="state"
                                        type="text"
                                        autoComplete="false"
                                        onChange={e => this.onChange(e.target)}
                                        value={this.state.selectedrow.state}
                                         />
                                     <span style={{ color: "red" }} > {this.state.errors.state}</span>

                                </div>
                                <div className="form-group">
                                <label for="Country">Country</label>
                                <input className="form-control"
                                        placeholder="Country"
                                        name="country"
                                        type="text"
                                        autoComplete="false"
                                        onChange={e => this.onChange(e.target)}
                                        value={this.state.selectedrow.country}
                                         />
                                     <span style={{ color: "red" }} > {this.state.errors.country}</span>

                                </div>
                                <label for="is_active">Status</label>
                                <div className="form-group">
                                    <select className="form-control" onChange={e => this.onChange(e.target)} name="is_active" >
                                        {/* <option>select</option> */}
                                        <option value='0' selected={this.state.selectedrow.is_active === 0 ? 'selected' : ''}>Inactive</option>
                                        <option value ='1' selected={this.state.selectedrow.is_active === 1 ? 'selected' : ''}>Active</option>
                                    </select>

                                </div>
                                <div className="form-group">
                                    <label for="is_verified">Email Verified</label>
                                    <select className="form-control" onChange={e => this.onChange(e.target)} name="is_verified" >
                                        {/* <option>select</option> */}
                                        <option value ='0' selected={this.state.selectedrow.is_verified === 0 ? 'selected' : ''}>Not Verified</option>
                                        <option value ='1' selected={this.state.selectedrow.is_verified === 1 ? 'selected' : ''}>Verified</option>
                                    </select>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={this.closeModal}>Close</button>
                                <button type="button" className="btn btn-primary" onClick={this.updateFan} >Update Fan</button>
                            </div>
                        </div>
                    </div>
                </div>
                <Pagination
                    changePage={this.changePage}  //function Call 
                    totalPages={this.state.totalPages}
                    limit={this.state.limit}
                    currentPage={this.state.pageNo}
                />
            </div>
        )
    };
}
export default Fan
